import React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/layout';
import Seo from "../components/seo"
import CTA from '../components/cta';

import BlogFeed from '../components/blog-feed';

import patternHex from '../images/backrounds/pattern-hex-white.jpg';
import NeedAQuote from "../components/need-a-quote";
import {StaticImage} from "gatsby-plugin-image";
import Quote from "../components/quote";


const recentCustomers = [
  <StaticImage
  src="../images/clients/zendesk-customers/asg.png"
  alt="Asg"
/>,
  <StaticImage
    src="../images/clients/zendesk-customers/singularity.png"
    alt="logo_Singularity"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/pureflix.png"
    alt="logo_pureflix"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/brasstaxes.png"
    alt="logo_brasstaxes"
  />
]

const Index = ({location}) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Zendesk Training"
      description="Looking for someone to train your Zendesk Admins? Let us take that effort off of your plate. We are a one-stop-shop for all things Zendesk."
    />
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${patternHex})`
        }}
      >
        <div className="hero-title bg-dark-transparent">
          <div className="hero-image">
            <StaticImage
              src="../images/clients/zendesk.png"
              alt="Zendesk Implementation Premier Partner"
              layout="constrained"
              style={{ maxWidth: "200px"}}
            />
          </div>
          <h1 className="text-white">
            zendesk training for agents and admins
          </h1>
          <p className="text-white">
            729 can help you migrate all of your customer data from your old tool into Zendesk and set you up for
            success. We will make sure you safely move all of your precious customer records including companies,
            conversations, users, account info, etc.</p>
          <CTA href="/contact-us/" className="btn btn-primary" text="Tell Us About Your Migration"/>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>we offer the following trainings focused on custom workflows</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <h3>train the trainer</h3>
            <p>729 provides one on one training to <Link href="/partnerships-zendesk/">Zendesk
              admins so you can manage your entire team yourself</Link>.</p>
            <ul className="text-primary m-0 pl-3">
              <li><span className="text-dark">Learn how to set up triggers, macros and improve the way you do automations</span>
              </li>
              <li><span className="text-dark">729 will teach you how to set up agent views</span>
              </li>
              <li><span className="text-dark">Get training on setting up standard and custom reporting</span>
              </li>
              <li><span className="text-dark">Learn how to use any feature in Zendesk in a do it yourself</span>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <h3>agent training</h3>
            <p>729 Solutions provides training to groups of agents to show them the exact steps needed to quickly and
              efficiently handle calls, chat, emails etc…</p>
            <ul className="text-primary m-0 pl-3">
              <li><span className="text-dark">Learn all the steps needed in any custom workflow</span>
              </li>
              <li><span className="text-dark">Handle ticket escalations properly</span>
              </li>
              <li><span className="text-dark">Quickly find customer information and keep accurate ticket logs</span>
              </li>
              <li><span className="text-dark">Entering data correctly</span>
              </li>
              <li><span className="text-dark">Customize views in Zendesk</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="row pt-6">
          <div className="col">
            <p>729 can also provide digital guides to show screenshots of all the steps required by your agents for any
              workflow. If you are hiring new agents these manuals can be invaluable to quickly help them handle calls
              and emails.</p>
            <div className="d-flex justify-content-center pt-4">
              <CTA text="Tell Us About Your Project" href='/contact-us/' className="btn btn-primary"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pb-6">
        <div className="row">
          <div className="col">
            <h2>recent zendesk customerS</h2>
          </div>
        </div>
        <div className="row align-items-center">
          {recentCustomers.map((component) => (
            <div className="col-12 col-lg-3">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <div className="row">
                    <div className="col-4 col-lg-12 ml-auto mr-auto mb-5">
                      {component}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="bg-dark">
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2 className="text-center text-white">Testimonials</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Quote
              dark
              name="Kelly"
              company="Luna360"
              quote="Our team was incredibly impressed with the Zendesk Administrator training. Dave was articulate, well-prepared, patient with our questions, and clearly demonstrated his subject matter expertise. He even moderated the pace of his speech to accommodate the American Sign Language interpreter who was on site for our Deaf and hard-of-hearing employees. It was a good experience for our entire team!"
            />
          </div>
          <div className="col-lg-6 d-flex pt-5">
            <div className="d-flex align-items-center">
            <div className="row">
              <div className="col-lg-9 m-auto">
                <StaticImage src="../images/clients/zendesk-customers/luna360.png" alt="luna360-logo"/>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <NeedAQuote />
    <BlogFeed containerClassname="py-6"/>
  </Layout>
);

export default Index;
