import CTA from "./cta";
import React from "react";
import classnames from 'classnames';


const NeedAQuote = ({dark, title, text, buttonText, href}) => (
  <section className={classnames({'bg-light': !dark, 'bg-dark': dark})}>
    <div className="container py-6">
      <div className="row">
        <div className="col">
          <div className="text-center">
            <h2 className={classnames("mb-2" , {'text-white': dark})}>{title}</h2>
            <p className={classnames({'text-white': dark})}>{text}</p>
            <CTA href={href ? href : "/contact-us/"} text={buttonText}
                 className={classnames("btn", {'btn-secondary-light': dark, 'btn-primary': !dark})}/>
          </div>
        </div>
      </div>
    </div>
  </section>
);

NeedAQuote.defaultProps = {
  dark: false,
  title: 'I Need a Quote',
  text: 'Reach out today. We’ll respond as soon as possible so we can talk about your project.',
  buttonText: 'Get a Quote'
}

export default NeedAQuote;
